import React from 'react'
import { twMerge } from 'tailwind-merge'
import { commonClassnames } from './constants'
import { ButtonProps } from './types'

export const Button = ({
  className,
  variant = 'primary',
  size = 'default',
  children,
  onClick,
}: ButtonProps) => {
  return (
    <button className={twMerge(commonClassnames(variant, size), className)} onClick={onClick}>
      {children}
    </button>
  )
}
