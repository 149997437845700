import React, { useState } from 'react'
import {
  TreeSelect,
  Form,
  InputNumber,
  Select,
  Flex,
  Checkbox,
  Radio,
  RadioChangeEvent,
} from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { Collapse } from '../Collapse'
import { Typography } from '../Typography'
import { weightUnits, categories } from './constants'
import { DimensionValueTypes, PeriodTypes, ProductForm } from './types'
import { validateProduct } from './validators'
import {
  calculateCheckIn,
  calculateFulfillment,
  calculateIspectAndGrade,
  calculatePutAway,
  calculateReceiving,
  calculateSanitizeAndRepackage,
  calculateSort,
  calculateStorage,
  calculateTestingAndDataWipe,
} from './calculations'
import './styles.css'

const DimensionSuffix = ({ children }: { children: React.ReactNode }) => (
  <div className="flex items-center justify-center bg-transparent px-3 py-0.5 text-xs text-[#16293F]">
    {children}
  </div>
)
const ErrorText = ({ children }: { children: React.ReactNode }) => (
  <div className="py-1.5 text-xs text-[#F44336]">{children}</div>
)

export const FeesCalculator = () => {
  const [state, setState] = useState<ProductForm>({
    dimDropdownValue: 'inches',
    weightDropdownValue: 'pounds',
    category: null,
    dimValue: {
      valueL: null,
      valueW: null,
      valueH: null,
      weight: null,
    },
    vas: {
      inspect: false,
      sanitize: false,
      test: false,
    },
  })
  const [period, setPeriod] = React.useState<PeriodTypes>(PeriodTypes.jan_sep)
  const [businessType, setBusinessType] = React.useState<string | number>('b2c')

  const onChangeInput = (value: string | null) => (elem: DimensionValueTypes) =>
    setState(prev => ({
      ...prev,
      dimValue: { ...prev.dimValue, [elem]: value },
    }))
  const onChangeDropdown = (name: string) => (value: string | null) =>
    setState(prev => ({ ...prev, [name]: value }))
  const onChangeCheckbox = (name: string) => (event: CheckboxChangeEvent) =>
    setState(prev => ({
      ...prev,
      vas: {
        ...prev.vas,
        [name]: event.target.checked,
      },
    }))
  const onChangeRadio = (name: string) => (event: RadioChangeEvent) => {
    if (name === 'period') {
      setPeriod(event.target.value)
    } else {
      setBusinessType(event.target.value)
    }
  }

  const checkboxesDisabled =
    !state.dimDropdownValue || !state.weightDropdownValue || !state.category
  const isInitalDimState =
    state.dimValue[DimensionValueTypes.H] === null &&
    state.dimValue[DimensionValueTypes.L] === null &&
    state.dimValue[DimensionValueTypes.W] === null
  const { dimensionsError, vasDimensionsError, vasWeightError, nonCalculatable } =
    validateProduct(state)

  const inspectValue = calculateIspectAndGrade({
    weight: Number(state.dimValue[DimensionValueTypes.P]),
    weightUnits: state.weightDropdownValue,
    categoryId: Number(state.category),
  })
  const sanitizeValue = calculateSanitizeAndRepackage({
    weight: Number(state.dimValue[DimensionValueTypes.P]),
    weightUnits: state.weightDropdownValue,
    categoryId: Number(state.category),
  })
  const testValue = calculateTestingAndDataWipe({
    weight: Number(state.dimValue[DimensionValueTypes.P]),
    weightUnits: state.weightDropdownValue,
    categoryId: Number(state.category),
  })
  const receiving = calculateReceiving({
    weight: Number(state.dimValue[DimensionValueTypes.P]),
    weightUnits: state.weightDropdownValue,
  })
  const checking = calculateCheckIn({
    weight: Number(state.dimValue[DimensionValueTypes.P]),
    weightUnits: state.weightDropdownValue,
  })
  const sorting = calculateSort({
    weight: Number(state.dimValue[DimensionValueTypes.P]),
    weightUnits: state.weightDropdownValue,
  })
  const putAway = calculatePutAway({
    weight: Number(state.dimValue[DimensionValueTypes.P]),
    weightUnits: state.weightDropdownValue,
  })
  const fulfillment = calculateFulfillment({
    weight: Number(state.dimValue[DimensionValueTypes.P]),
    weightUnits: state.weightDropdownValue,
  })
  const storage = calculateStorage({
    length: Number(state.dimValue[DimensionValueTypes.L]),
    width: Number(state.dimValue[DimensionValueTypes.W]),
    height: Number(state.dimValue[DimensionValueTypes.H]),
  })
  const chosenStorageValue =
    period === PeriodTypes.jan_sep ? storage[PeriodTypes.jan_sep] : storage[PeriodTypes.oct_dec]
  const chosenFulfillmentValue = businessType === 'b2c' ? fulfillment.b2c : fulfillment.b2b
  const receivingAndPutAway = receiving + checking + sorting + putAway

  const showVASSum = Object.values(state.vas).some(Boolean) && !nonCalculatable
  const vasSum =
    (state.vas.inspect ? inspectValue : 0) +
    (state.vas.sanitize ? sanitizeValue : 0) +
    (state.vas.test ? testValue : 0)
  const totalOneTimeFeesValue = chosenFulfillmentValue + receivingAndPutAway + vasSum

  return (
    <div className="mt-5 flex">
      {/* Left Section */}
      <Form className="flex w-1/2 grow flex-col items-start rounded-l-3xl p-12 ring-1 ring-gray-200">
        <div className="flex">
          <p className="body-text font-uniform-rnd text-nav-item-text">
            Tell us about your product.
          </p>
        </div>
        <Typography.H5 className="mb-3 mt-6">Dimensions</Typography.H5>
        <Flex align="center">
          <Form.Item className="mb-0">
            <InputNumber
              size="large"
              className="input-number-custom-right w-full"
              value={state.dimValue[DimensionValueTypes.L]}
              addonAfter={<DimensionSuffix>L</DimensionSuffix>}
              onChange={val => onChangeInput(val)(DimensionValueTypes.L)}
            />
          </Form.Item>
          <Form.Item className="mb-0">
            <InputNumber
              size="large"
              className="input-number-custom-both w-full"
              value={state.dimValue[DimensionValueTypes.W]}
              addonAfter={<DimensionSuffix>W</DimensionSuffix>}
              onChange={val => onChangeInput(val)(DimensionValueTypes.W)}
            />
          </Form.Item>
          <Form.Item className="mb-0">
            <InputNumber
              size="large"
              className="input-number-custom-left w-full"
              value={state.dimValue[DimensionValueTypes.H]}
              addonAfter={<DimensionSuffix>H</DimensionSuffix>}
              onChange={val => onChangeInput(val)(DimensionValueTypes.H)}
            />
          </Form.Item>
          <DimensionSuffix>in.</DimensionSuffix>
        </Flex>
        <ErrorText>{isInitalDimState ? null : dimensionsError}</ErrorText>
        <div className="divider mt-9" />
        <Typography.H5 className="mb-3 mt-6">Weight</Typography.H5>
        <Form.Item>
          <InputNumber
            size="large"
            className="w-[200px]"
            value={state.dimValue[DimensionValueTypes.P]}
            addonAfter={
              <Form.Item noStyle name="suffix">
                <Select className="w-[100px]" onChange={onChangeDropdown('weightDropdownValue')}>
                  {weightUnits.map(unit => (
                    <Select.Option key={unit.value} value={unit.value}>
                      {unit.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            }
            onChange={value => onChangeInput(value)(DimensionValueTypes.P)}
          />
        </Form.Item>
        <div className="divider mt-9" />
        <Typography.H5 className="mb-3 mt-6">Category</Typography.H5>
        <TreeSelect
          showSearch
          allowClear
          treeDataSimpleMode
          size="large"
          className="w-full"
          value={state.category}
          treeData={categories}
          onChange={category => setState(prev => ({ ...prev, category }))}
        />
        <div className="divider mt-9" />
        <Typography.H5 className="mb-3 mt-6">Value-Added Services</Typography.H5>
        <div className="flex flex-col items-start">
          <Checkbox
            disabled={checkboxesDisabled}
            checked={state.vas.inspect}
            onChange={onChangeCheckbox('inspect')}
          >
            Inspect and grade
          </Checkbox>
          <Checkbox
            disabled={checkboxesDisabled}
            checked={state.vas.test}
            onChange={onChangeCheckbox('test')}
          >
            Test and data wipe
          </Checkbox>
          <Checkbox
            disabled={checkboxesDisabled}
            checked={state.vas.sanitize}
            onChange={onChangeCheckbox('sanitize')}
          >
            Sanitize and repackage
          </Checkbox>
        </div>
        <ErrorText>{`${vasDimensionsError || ''} ${vasWeightError || ''}`}</ErrorText>
      </Form>

      {/* Right Section */}
      <div className="flex w-1/2 flex-col justify-between rounded-r-3xl bg-primary-navy-dark pt-12 ring-1 ring-gray-900">
        <div className="flex flex-col px-12">
          <Typography.H5 className="mb-3 text-white">Recurring Fees</Typography.H5>
          <Collapse
            target={
              <div className="flex grow justify-between text-xl text-inherit">
                <div>Storage</div>
                <div className="font-bold">
                  {nonCalculatable ? 'N/A' : `$${chosenStorageValue}`}
                </div>
              </div>
            }
          >
            <Radio.Group value={period} className="w-full" onChange={onChangeRadio('period')}>
              <div className="ml-9 mt-3 flex grow flex-col items-start">
                <div className="flex w-full justify-between">
                  <Radio value={PeriodTypes.jan_sep} className="text-white">
                    January-September
                  </Radio>
                  <div className="text-xl font-bold text-white">
                    {nonCalculatable ? 'N/A' : `$${storage[PeriodTypes.jan_sep]}`}
                  </div>
                </div>
                <div className="my-2" />
                <div className="flex w-full justify-between">
                  <Radio value={PeriodTypes.oct_dec} className="text-white">
                    October-December
                  </Radio>
                  <div className="text-xl font-bold text-white">
                    {nonCalculatable ? 'N/A' : `$${storage[PeriodTypes.oct_dec]}`}
                  </div>
                </div>
              </div>
            </Radio.Group>
          </Collapse>
          <Typography.H5 className="my-3 text-white">One-Time Fees</Typography.H5>
          <Collapse
            target={
              <div className="flex grow justify-between text-xl text-inherit">
                <div>Receiving and Put Away</div>
                <div className="font-bold">
                  {nonCalculatable ? 'N/A' : `$${receivingAndPutAway.toFixed(2)}`}
                </div>
              </div>
            }
          >
            <div className="ml-10 mt-3 flex grow flex-col items-start">
              <div className="mt-3 flex w-full justify-between text-xl text-white">
                <div>Receive</div>
                <div className="font-bold">
                  {nonCalculatable ? 'N/A' : `$${receiving.toFixed(2)}`}
                </div>
              </div>
              <div className="mt-3 flex w-full justify-between text-xl text-white">
                <div>Check In</div>
                <div className="font-bold">
                  {nonCalculatable ? 'N/A' : `$${checking.toFixed(2)}`}
                </div>
              </div>
              <div className="mt-3 flex w-full justify-between text-xl text-white">
                <div>Sort</div>
                <div className="font-bold">
                  {nonCalculatable ? 'N/A' : `$${sorting.toFixed(2)}`}
                </div>
              </div>
              <div className="mt-3 flex w-full justify-between text-xl text-white">
                <div>Put Away</div>
                <div className="font-bold">
                  {nonCalculatable ? 'N/A' : `$${putAway.toFixed(2)}`}
                </div>
              </div>
            </div>
          </Collapse>
          <Collapse
            target={
              <div className="flex grow justify-between text-xl text-inherit">
                <div>Fulfillment</div>
                <div className="font-bold">
                  {nonCalculatable ? 'N/A' : `$${chosenFulfillmentValue.toFixed(2)}`}
                </div>
              </div>
            }
          >
            <div className="flex flex-col">
              <Radio.Group
                value={businessType}
                className="w-full"
                onChange={onChangeRadio('businessType')}
              >
                <div className="ml-9 mt-3 flex grow flex-col items-start">
                  <div className="flex w-full justify-between">
                    <Radio value="b2c" className="text-white">
                      B2C
                    </Radio>
                    <div className="text-xl font-bold text-white">
                      {nonCalculatable ? 'N/A' : `$${fulfillment.b2c.toFixed(2)}`}
                    </div>
                  </div>
                  <div className="my-2" />
                  <div className="flex w-full justify-between">
                    <Radio value="b2b" className="text-white">
                      B2B
                    </Radio>
                    <div className="text-xl font-bold text-white">
                      {nonCalculatable ? 'N/A' : `$${fulfillment.b2b.toFixed(2)}`}
                    </div>
                  </div>
                </div>
              </Radio.Group>
              <div className="bg-grey-800 mt-8 rounded-sm px-4 py-2 text-white">
                For reCommerce, a 10% fee will apply towards each sale&apos;s final price plus any
                applicable marketplace fees. See full list of fees
              </div>
            </div>
          </Collapse>
          <Collapse
            target={
              <div className="flex grow justify-between text-xl text-inherit">
                <div>Value-Add Services</div>
                <div className="font-bold">{showVASSum ? `$${vasSum.toFixed(2)}` : 'N/A'}</div>
              </div>
            }
          >
            <div className="ml-10 mt-3 flex grow flex-col items-start">
              {state.vas.inspect && (
                <div className="mt-3 flex w-full justify-between text-xl text-white">
                  <div>Inspect and Grade</div>
                  <div className="font-bold">
                    {nonCalculatable ? 'N/A' : `$${inspectValue.toFixed(2)}`}
                  </div>
                </div>
              )}
              {state.vas.test && (
                <div className="mt-3 flex w-full justify-between text-xl text-white">
                  <div>Test and Data Wipe</div>
                  <div className="font-bold">
                    {nonCalculatable ? 'N/A' : `$${testValue.toFixed(2)}`}
                  </div>
                </div>
              )}
              {state.vas.sanitize && (
                <div className="mt-3 flex w-full justify-between text-xl text-white">
                  <div>Sanitize and Repackage</div>
                  <div className="font-bold">
                    {nonCalculatable ? 'N/A' : `$${sanitizeValue.toFixed(2)}`}
                  </div>
                </div>
              )}
            </div>
          </Collapse>
        </div>
        <div className="bg-primary-dark mt-5 flex gap-12 rounded-br-3xl p-12">
          <div className="flex flex-col">
            <p className="font-bold text-white">Total recurring fee estimate</p>
            <div className="flex">
              <Typography.H2 className="text-white">
                {nonCalculatable ? 'N/A' : `$${chosenStorageValue}`}
              </Typography.H2>
              <div className="ml-3 flex flex-col">
                <p className="text-white">per unit</p>
                <p className="text-white">per month</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <p className="font-bold text-white">Total one-time fees estimate</p>
            <div className="flex">
              <Typography.H2 className="text-white">
                {nonCalculatable ? 'N/A' : `$${totalOneTimeFeesValue.toFixed(2)}`}
              </Typography.H2>
              <p className="ml-3 text-white">per unit</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
