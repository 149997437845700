import { outOfVASValidationCategoriesIds } from './constants'
import {
  DimensionValueTypes,
  ProductForm,
  ProductValidationErrors,
  ValidationType,
  Value,
} from './types'

const mapValidationTypeToMaxDimLength: Record<ValidationType, number> = {
  base: 60,
  vas: 60,
}
const mapValidationTypeToMaxSize: Record<ValidationType, number> = {
  base: 100,
  vas: 75,
}
const mapValidationTypeToMaxWeight: Record<ValidationType, number> = {
  base: 100,
  vas: 55,
}

const validatePositive = (value: Value) => value && Number(value) > 0

const validateDimension = (dim: Value, validationType: ValidationType) => {
  if (!dim) {
    return true
  }

  const max = mapValidationTypeToMaxDimLength[validationType]

  return Number(dim) < max && validatePositive(dim)
}

const validateSize = (dim1: Value, dim2: Value, dim3: Value, validationType: ValidationType) => {
  if (!dim1 || !dim2 || !dim3) {
    return true
  }

  const numericDim1 = Number(dim1)
  const numericDim2 = Number(dim2)
  const numericDim3 = Number(dim3)

  const max = mapValidationTypeToMaxSize[validationType]

  return (numericDim1 * numericDim2 * numericDim3) / 139 < max
}

const validateWeight = (dimUnits: string, weight: Value, validationType: ValidationType) => {
  if (!weight) {
    return true
  }

  const max = mapValidationTypeToMaxWeight[validationType]

  if (dimUnits === 'pounds' && Number(weight) > max) {
    return false
  }
  if (dimUnits === 'ounces' && Number(weight) > max * 16) {
    return false
  }

  return validatePositive(weight)
}

export const validateProduct = ({
  category,
  dimValue,
  weightDropdownValue,
  vas,
}: ProductForm): ProductValidationErrors => {
  let dimensionsError = null
  let vasDimensionsError = null
  let weightError = null
  let vasWeightError = null
  let nonCalculatable = false

  if (!category) {
    const notPositiveDimension =
      !validatePositive(dimValue[DimensionValueTypes.L]) ||
      !validatePositive(dimValue[DimensionValueTypes.W]) ||
      !validatePositive(dimValue[DimensionValueTypes.H])
    const notPositiveWeight = !validatePositive(dimValue[DimensionValueTypes.P])

    return {
      dimensionsError: notPositiveDimension ? 'Every side must be more than 0.' : null,
      vasDimensionsError,
      weightError: notPositiveWeight ? 'Weight must be more than 0.' : null,
      vasWeightError,
      nonCalculatable,
    }
  }

  if (
    Object.values(vas).some(val => val) &&
    outOfVASValidationCategoriesIds.includes(`${category}`)
  ) {
    nonCalculatable = true
  }

  if (
    !validateDimension(dimValue[DimensionValueTypes.L], 'base') ||
    !validateDimension(dimValue[DimensionValueTypes.W], 'base') ||
    !validateDimension(dimValue[DimensionValueTypes.H], 'base')
  ) {
    dimensionsError = 'Side length must be in range 1-60 inches.'
    nonCalculatable = true
  }

  if (
    !validateSize(
      dimValue[DimensionValueTypes.L],
      dimValue[DimensionValueTypes.W],
      dimValue[DimensionValueTypes.H],
      'base',
    )
  ) {
    dimensionsError = 'Your product is oversize.'
    nonCalculatable = true
  }

  if (!validateWeight(weightDropdownValue, dimValue[DimensionValueTypes.P], 'base')) {
    weightError = "Product's weight must be in range 1-100 pounds (1-1600 ounces)."
    nonCalculatable = true
  }

  // validate value-add services if checked
  if (Object.values(vas).some(val => val)) {
    if (
      !validateSize(
        dimValue[DimensionValueTypes.L],
        dimValue[DimensionValueTypes.W],
        dimValue[DimensionValueTypes.H],
        'vas',
      )
    ) {
      vasDimensionsError = 'Your product is oversize.'
      nonCalculatable = true
    }

    if (!validateWeight(weightDropdownValue, dimValue[DimensionValueTypes.P], 'vas')) {
      vasWeightError = "Product's weight must be in range 1-55 pounds (1-880 ounces)."
      nonCalculatable = true
    }
  }

  return {
    dimensionsError,
    vasDimensionsError,
    weightError,
    vasWeightError,
    nonCalculatable,
  }
}
