export enum DimensionValueTypes {
  L = 'valueL',
  W = 'valueW',
  H = 'valueH',
  P = 'weight',
}

export type Value = null | string

export type ProductForm = {
  dimDropdownValue: string
  weightDropdownValue: string
  category: number | null
  dimValue: {
    [DimensionValueTypes.L]: Value
    [DimensionValueTypes.W]: Value
    [DimensionValueTypes.H]: Value
    [DimensionValueTypes.P]: Value
  }
  vas: {
    inspect: boolean
    test: boolean
    sanitize: boolean
  }
}

export type ProductValidationErrors = {
  dimensionsError: string | null
  vasDimensionsError: string | null
  weightError: string | null
  vasWeightError: string | null
  nonCalculatable: boolean
}
export type ValidationType = 'base' | 'vas'

export type ProductFeesSectionConfig = {
  storage: boolean
  recievingAndPutaway: boolean
  fulfillment: boolean
  valueAddServices: boolean
}

export enum PeriodTypes {
  jan_sep = 'jan-sep',
  oct_dec = 'oct-dec',
}
