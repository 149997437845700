import cn from 'classnames'
import { Link } from 'gatsby'
import React from 'react'
import { commonClassnames } from './constants'
import { ButtonLinkProps } from './types'

export const ButtonLink = ({
  to,
  target,
  className,
  size = 'default',
  variant = 'primary',
  children,
}: ButtonLinkProps) => {
  return (
    <Link
      to={to}
      target={target}
      className={cn('inline-block', commonClassnames(variant, size), className)}
    >
      {children}
    </Link>
  )
}
