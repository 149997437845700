import { PeriodTypes } from './types'

type CalculationParams = {
  weight: number
  weightUnits: string
  categoryId: number
}

type CalculateStorageParams = {
  length: number
  width: number
  height: number
}

export const calculateIspectAndGrade = ({
  weight,
  weightUnits,
  categoryId,
}: CalculationParams): number => {
  const automotiveBabyBooksClothing = categoryId >= 2 && categoryId <= 50 // Automotive, Baby, Books, Clothing category
  const electroniceIPTVCamerasCarAudio = [51, 53, 61, 86, 94, 114, 142, 170, 189].includes(
    categoryId,
  ) // specific Electronics
  const phonesDronesHomeAudio = [
    71, 76, 77, 80, 81, 82, 83, 84, 126, 128, 129, 130, 131, 137, 138, 139, 140, 141, 143, 144, 145,
    146, 150,
  ].includes(categoryId) // specific Electronics (phones, drones, home audio/video)
  const printersScanners = categoryId >= 156 && categoryId <= 164 // printers/scanners
  const jewelry = categoryId >= 319 && categoryId <= 328 // Jewelry
  const music = categoryId >= 338 && categoryId <= 343 // Music
  const sporting = categoryId >= 376 && categoryId <= 394 // Sporting
  const homeAppliances = categoryId === 248
  const homeHeatingCooling = categoryId >= 279 && categoryId <= 284
  const bedding = categoryId >= 272 && categoryId <= 278
  const home =
    !homeAppliances && !homeHeatingCooling && !bedding && categoryId >= 247 && categoryId <= 313 // Home
  const computers = [85, 121].includes(categoryId) // computers
  const computersDesktop = categoryId === 120 // computers desktop
  const chromebooks = categoryId === 413 // Chromebooks
  const laptops = categoryId === 122 // Laptops
  const monitorsTv = [123, 175, 176].includes(categoryId) // Monitors, tv, lcd, led
  const headphones = categoryId >= 132 && categoryId <= 136 // Headphones
  const tablets = categoryId >= 165 && categoryId <= 169 // Tablets
  const videoGames = categoryId === 179 || (categoryId >= 185 && categoryId <= 188) // Video games
  const videoGamesConsoles = categoryId >= 180 && categoryId <= 184 // Video games consoles
  const wearableTech = categoryId === 207 || (categoryId >= 209 && categoryId <= 212) // Wearable tech
  const patioGarden = categoryId >= 360 && categoryId <= 374 // Pation & garden
  const toys = categoryId >= 395 && categoryId <= 412 // toys

  if (
    (weightUnits === 'ounces' && weight > 0 && weight <= 16) ||
    (weightUnits === 'pounds' && weight > 0 && weight <= 2)
  ) {
    if (automotiveBabyBooksClothing) {
      return 0.27
    }
    if (electroniceIPTVCamerasCarAudio || jewelry || music || sporting) {
      return 0.54
    }
    if (phonesDronesHomeAudio || printersScanners || home) {
      return 1.07
    }
    if (computers) {
      return 2.11
    }
    if (computersDesktop) {
      return 1.46
    }
    if (chromebooks) {
      return 0.48
    }
    if (laptops) {
      return 1.28
    }
    if (monitorsTv) {
      return 2.76
    }
    if (headphones) {
      return 2.14
    }
    if (tablets) {
      return 2.5
    }
    if (videoGames) {
      return 1.64
    }
    if (wearableTech) {
      return 4.05
    }
    if (homeAppliances) {
      return 2.76
    }
    if (bedding) {
      return 0.35
    }
    if (homeHeatingCooling) {
      return 1.61
    }
    if (patioGarden) {
      return 0.8
    }
    if (toys) {
      return 0.35
    }
  }

  if (weightUnits === 'pounds') {
    if (weight > 2 && weight <= 5) {
      if (automotiveBabyBooksClothing) {
        return 0.36
      }
      if (electroniceIPTVCamerasCarAudio || jewelry || music || sporting) {
        return 0.72
      }
      if (phonesDronesHomeAudio || printersScanners || home) {
        return 1.45
      }
      if (computers) {
        return 2.84
      }
      if (computersDesktop) {
        return 1.97
      }
      if (chromebooks) {
        return 0.64
      }
      if (laptops) {
        return 1.73
      }
      if (monitorsTv) {
        return 3.73
      }
      if (headphones) {
        return 2.89
      }
      if (tablets) {
        return 3.38
      }
      if (videoGames) {
        return 2.21
      }
      if (videoGamesConsoles) {
        return 2.05
      }
      if (wearableTech) {
        return 5.47
      }
      if (homeAppliances) {
        return 3.73
      }
      if (bedding) {
        return 0.48
      }
      if (homeHeatingCooling) {
        return 2.17
      } else if (patioGarden) {
        return 1.08
      } else if (toys) {
        return 0.48
      }
    } else if (weight > 5 && weight <= 10) {
      if (automotiveBabyBooksClothing) {
        return 0.63
      } else if (electroniceIPTVCamerasCarAudio || jewelry || music || sporting) {
        return 1.26
      } else if (phonesDronesHomeAudio || printersScanners || home) {
        return 2.53
      }
      if (computers) {
        return 4.97
      } else if (computersDesktop) {
        return 3.45
      } else if (chromebooks) {
        return 1.12
      } else if (laptops) {
        return 3.03
      } else if (monitorsTv) {
        return 6.53
      } else if (headphones) {
        return 5.06
      } else if (tablets) {
        return 5.91
      } else if (videoGames) {
        return 3.88
      } else if (videoGamesConsoles) {
        return 3.59
      } else if (wearableTech) {
        return 9.57
      } else if (homeAppliances) {
        return 6.53
      } else if (bedding) {
        return 0.83
      } else if (homeHeatingCooling) {
        return 3.79
      } else if (patioGarden) {
        return 1.9
      } else if (toys) {
        return 0.83
      }
    } else if (weight > 10 && weight <= 20) {
      if (automotiveBabyBooksClothing) {
        return 1.11
      } else if (electroniceIPTVCamerasCarAudio || jewelry || music || sporting) {
        return 2.21
      } else if (phonesDronesHomeAudio || printersScanners || home) {
        return 4.43
      }
      if (computers) {
        return 8.71
      } else if (computersDesktop) {
        return 6.03
      } else if (chromebooks) {
        return 1.96
      } else if (laptops) {
        return 5.29
      } else if (monitorsTv) {
        return 11.42
      } else if (headphones) {
        return 8.85
      } else if (tablets) {
        return 10.34
      } else if (videoGames) {
        return 6.78
      } else if (videoGamesConsoles) {
        return 6.28
      } else if (wearableTech) {
        return 16.74
      } else if (homeAppliances) {
        return 11.42
      } else if (bedding) {
        return 1.46
      } else if (homeHeatingCooling) {
        return 6.64
      } else if (patioGarden) {
        return 3.32
      } else if (toys) {
        return 1.46
      }
    } else if (weight > 20 && weight <= 50) {
      if (automotiveBabyBooksClothing) {
        return 1.38
      } else if (electroniceIPTVCamerasCarAudio || jewelry || music || sporting) {
        return 2.77
      } else if (phonesDronesHomeAudio || printersScanners || home) {
        return 5.53
      }
      if (computers) {
        return 10.88
      } else if (computersDesktop) {
        return 7.54
      } else if (chromebooks) {
        return 2.45
      } else if (laptops) {
        return 6.62
      } else if (monitorsTv) {
        return 14.28
      } else if (headphones) {
        return 11.07
      } else if (tablets) {
        return 12.92
      } else if (videoGames) {
        return 8.48
      } else if (videoGamesConsoles) {
        return 7.85
      } else if (wearableTech) {
        return 20.93
      } else if (homeAppliances) {
        return 14.28
      } else if (bedding) {
        return 1.83
      } else if (homeHeatingCooling) {
        return 8.3
      } else if (patioGarden) {
        return 4.15
      } else if (toys) {
        return 1.83
      }
    } else if (weight > 50 && weight <= 55) {
      if (automotiveBabyBooksClothing) {
        return 3.46
      } else if (electroniceIPTVCamerasCarAudio || jewelry || music || sporting) {
        return 6.92
      } else if (phonesDronesHomeAudio || printersScanners || home) {
        return 13.84
      }
      if (computers) {
        return 27.2
      } else if (computersDesktop) {
        return 18.84
      } else if (chromebooks) {
        return 6.14
      } else if (laptops) {
        return 16.54
      } else if (monitorsTv) {
        return 35.69
      } else if (headphones) {
        return 27.67
      } else if (tablets) {
        return 32.3
      } else if (videoGames) {
        return 21.2
      } else if (videoGamesConsoles) {
        return 19.63
      } else if (wearableTech) {
        return 52.33
      } else if (homeAppliances) {
        return 17.85
      } else if (bedding) {
        return 4.57
      } else if (homeHeatingCooling) {
        return 10.38
      } else if (patioGarden) {
        return 10.38
      } else if (toys) {
        return 2.28
      }
    }
  }

  return 0
}

export const calculateSanitizeAndRepackage = ({
  weight,
  weightUnits,
  categoryId,
}: CalculationParams): number => {
  const automotiveBabyBooksClothing = categoryId >= 2 && categoryId <= 50 // Automotive, Baby, Books, Clothing category
  const electroniceIPTVCamerasCarAudio = [51, 53, 61, 86, 94, 114, 142, 170, 189].includes(
    categoryId,
  ) // specific Electronics
  const cellularPhones = [71, 76, 77, 80, 81, 82, 83, 84].includes(categoryId) // cellular phones
  const computers = [85, 120, 121].includes(categoryId) // Home
  const chromebooks = categoryId === 413 // Chromebooks
  const laptops = categoryId === 122 // Laptops
  const monitors = categoryId === 123 // Monitors, tv, lcd, led
  const tvs = [175, 176].includes(categoryId) // Monitors, tv, lcd, led
  const otherElectronics = [
    126, 128, 129, 130, 131, 137, 138, 139, 140, 141, 143, 144, 145, 146, 150,
  ].includes(categoryId) // cellular phones
  const printersScanners = categoryId >= 156 && categoryId <= 164 // printers/scanners
  const wearableTech = categoryId === 207 || (categoryId >= 209 && categoryId <= 212) // Wearable tech
  const bedding = categoryId >= 272 && categoryId <= 278
  const jewelry = categoryId >= 319 && categoryId <= 328 // Jewelry
  const music = categoryId >= 338 && categoryId <= 343 // Music
  const patioGarden = categoryId >= 360 && categoryId <= 374 // Pation & garden
  const sporting = categoryId >= 376 && categoryId <= 394 // Sporting
  const toys = categoryId >= 395 && categoryId <= 412 // toys
  const headphones = categoryId >= 132 && categoryId <= 136 // Headphones
  const tablets = categoryId >= 165 && categoryId <= 169 // Tablets
  const videoGames = categoryId === 179 || (categoryId >= 185 && categoryId <= 188) // Video games
  const videoGamesConsoles = categoryId >= 180 && categoryId <= 184 // Video games consoles
  const home = !bedding && categoryId >= 247 && categoryId <= 313 // Home

  if (
    (weightUnits === 'ounces' && weight > 0 && weight <= 16) ||
    (weightUnits === 'pounds' && weight > 0 && weight <= 2)
  ) {
    if (automotiveBabyBooksClothing || electroniceIPTVCamerasCarAudio) {
      return 0.9
    }
    if (cellularPhones) {
      return 1.79
    }
    if (computers) {
      return 2.73
    }
    if (chromebooks || laptops) {
      return 1.34
    }
    if (monitors) {
      return 1.2
    }
    if (tvs) {
      return 1.2
    }
    if (
      otherElectronics ||
      printersScanners ||
      wearableTech ||
      bedding ||
      jewelry ||
      music ||
      patioGarden ||
      sporting
    ) {
      return 0.45
    }
    if (home || toys) {
      return 0.45
    }
    if (headphones) {
      return 3.58
    }
    if (tablets) {
      return 5.21
    }
    if (videoGames) {
      return 3.65
    }
    if (videoGamesConsoles) {
      return 3.65
    }
  }

  if (weightUnits === 'pounds') {
    if (weight > 2 && weight <= 5) {
      if (automotiveBabyBooksClothing || electroniceIPTVCamerasCarAudio) {
        return 1.21
      }
      if (cellularPhones) {
        return 2.42
      }
      if (computers) {
        return 3.69
      }
      if (chromebooks || laptops) {
        return 1.81
      }
      if (monitors) {
        return 1.62
      }
      if (tvs) {
        return 1.62
      }
      if (
        otherElectronics ||
        printersScanners ||
        wearableTech ||
        bedding ||
        jewelry ||
        music ||
        patioGarden ||
        sporting
      ) {
        return 0.6
      }
      if (home || toys) {
        return 0.6
      }
      if (headphones) {
        return 4.84
      }
      if (tablets) {
        return 7.03
      }
      if (videoGames) {
        return 4.92
      }
      if (videoGamesConsoles) {
        return 4.56
      }
    } else if (weight > 5 && weight <= 10) {
      if (automotiveBabyBooksClothing || electroniceIPTVCamerasCarAudio) {
        return 2.12
      }
      if (cellularPhones) {
        return 4.23
      }
      if (computers) {
        return 6.46
      }
      if (chromebooks || laptops) {
        return 3.17
      }
      if (monitors) {
        return 2.84
      }
      if (tvs) {
        return 2.84
      }
      if (
        otherElectronics ||
        printersScanners ||
        wearableTech ||
        bedding ||
        jewelry ||
        music ||
        patioGarden ||
        sporting
      ) {
        return 1.06
      }
      if (home || toys) {
        return 1.06
      }
      if (headphones) {
        return 8.47
      } else if (tablets) {
        return 12.3
      } else if (videoGames) {
        return 8.61
      } else if (videoGamesConsoles) {
        return 7.98
      }
    } else if (weight > 10 && weight <= 20) {
      if (automotiveBabyBooksClothing || electroniceIPTVCamerasCarAudio) {
        return 3.7
      } else if (cellularPhones) {
        return 7.41
      } else if (computers) {
        return 11.3
      } else if (chromebooks || laptops) {
        return 5.56
      } else if (monitors) {
        return 4.97
      } else if (tvs) {
        return 4.97
      } else if (
        otherElectronics ||
        printersScanners ||
        wearableTech ||
        bedding ||
        jewelry ||
        music ||
        patioGarden ||
        sporting
      ) {
        return 1.85
      } else if (home || toys) {
        return 1.85
      } else if (headphones) {
        return 14.81
      } else if (tablets) {
        return 21.53
      } else if (videoGames) {
        return 15.07
      } else if (videoGamesConsoles) {
        return 13.96
      }
    } else if (weight > 20 && weight <= 50) {
      if (automotiveBabyBooksClothing || electroniceIPTVCamerasCarAudio) {
        return 4.63
      } else if (cellularPhones) {
        return 9.26
      } else if (computers) {
        return 14.13
      } else if (chromebooks || laptops) {
        return 6.94
      } else if (monitors) {
        return 6.22
      } else if (tvs) {
        return 5.47
      } else if (
        otherElectronics ||
        printersScanners ||
        wearableTech ||
        bedding ||
        jewelry ||
        music ||
        patioGarden ||
        sporting
      ) {
        return 2.31
      } else if (home || toys) {
        return 2.31
      } else if (headphones) {
        return 18.52
      } else if (tablets) {
        return 26.92
      } else if (videoGames) {
        return 18.84
      } else if (videoGamesConsoles) {
        return 17.45
      }
    } else if (weight > 50 && weight <= 55) {
      if (automotiveBabyBooksClothing || electroniceIPTVCamerasCarAudio) {
        return 11.57
      } else if (cellularPhones) {
        return 23.15
      } else if (computers) {
        return 35.33
      } else if (chromebooks || laptops) {
        return 17.36
      } else if (monitors) {
        return 15.53
      } else if (tvs) {
        return 6.02
      } else if (
        otherElectronics ||
        printersScanners ||
        wearableTech ||
        bedding ||
        jewelry ||
        music ||
        patioGarden ||
        sporting
      ) {
        return 5.79
      } else if (home || toys) {
        return 2.89
      } else if (headphones) {
        return 46.3
      } else if (tablets) {
        return 67.29
      } else if (videoGames) {
        return 47.1
      } else if (videoGamesConsoles) {
        return 43.61
      }
    }
  }

  return 0
}

export const calculateTestingAndDataWipe = ({
  weight,
  weightUnits,
  categoryId,
}: CalculationParams): number => {
  const automotiveBabyBooksClothing = categoryId >= 2 && categoryId <= 50 // Automotive, Baby, Books, Clothing category
  const electroniceIPTVCamerasCarAudio = [51, 53, 61, 86, 94, 114, 142, 170, 189].includes(
    categoryId,
  ) // specific Electronics
  const otherElectronics = [
    126, 128, 129, 130, 131, 137, 138, 139, 140, 141, 143, 144, 145, 146, 150,
  ].includes(categoryId) // cellular phones
  const printersScanners = categoryId >= 156 && categoryId <= 164 // printers/scanners
  const toys = categoryId >= 395 && categoryId <= 412 // toys
  const cellularPhones = [71, 76, 77, 80, 81, 82, 83, 84].includes(categoryId) // cellular phones
  const computers = [85, 120].includes(categoryId) // Computers
  const allInOne = categoryId === 121 // Computers all-in-one
  const chromebooks = categoryId === 413 // Chromebooks
  const laptops = categoryId === 122 // Laptops
  const monitors = categoryId === 123 // Monitors
  const tvs = [175, 176].includes(categoryId) // TV, lcd, led
  const headphones = categoryId >= 132 && categoryId <= 136 // Headphones
  const tablets = categoryId >= 165 && categoryId <= 169 // Tablets
  const wearableTech = categoryId === 207 || (categoryId >= 209 && categoryId <= 212) // Wearable tech
  const videoGames = categoryId === 179 || (categoryId >= 185 && categoryId <= 188) // Video games
  const videoGamesConsoles = categoryId >= 180 && categoryId <= 184 // Video games consoles
  const bedding = categoryId >= 272 && categoryId <= 278
  const jewelry = categoryId >= 319 && categoryId <= 328 // Jewelry
  const music = categoryId >= 338 && categoryId <= 343 // Music
  const patioGarden = categoryId >= 360 && categoryId <= 374 // Pation & garden
  const sporting = categoryId >= 376 && categoryId <= 394 // Sporting
  const home = !bedding && categoryId >= 247 && categoryId <= 313 // Home

  if (
    (weightUnits === 'ounces' && weight > 0 && weight <= 16) ||
    (weightUnits === 'pounds' && weight > 0 && weight <= 2)
  ) {
    if (
      automotiveBabyBooksClothing ||
      electroniceIPTVCamerasCarAudio ||
      otherElectronics ||
      printersScanners ||
      toys
    ) {
      return 0.71
    }
    if (cellularPhones) {
      return 3.94
    }
    if (computers) {
      return 8.41
    }
    if (allInOne) {
      return 7.9
    }
    if (chromebooks) {
      return 1.78
    }
    if (laptops) {
      return 4.8
    }
    if (monitors || tvs) {
      return 2.53
    }
    if (headphones) {
      return 7.13
    }
    if (tablets || wearableTech) {
      return 5.7
    }
    if (videoGames || videoGamesConsoles) {
      return 1.92
    }
    if (home || bedding || jewelry || music || patioGarden || sporting) {
      return 0.53
    }
  }

  if (weightUnits === 'pounds') {
    if (weight > 2 && weight <= 5) {
      if (
        automotiveBabyBooksClothing ||
        electroniceIPTVCamerasCarAudio ||
        otherElectronics ||
        printersScanners ||
        toys
      ) {
        return 0.96
      }
      if (cellularPhones) {
        return 5.32
      }
      if (computers) {
        return 11.35
      }
      if (allInOne) {
        return 10.66
      }
      if (chromebooks) {
        return 2.4
      }
      if (laptops) {
        return 6.48
      }
      if (monitors || tvs) {
        return 3.41
      }
      if (headphones) {
        return 9.62
      }
      if (tablets || wearableTech) {
        return 7.7
      }
      if (videoGames) {
        return 2.59
      }
      if (videoGamesConsoles) {
        return 2.4
      }
      if (home || bedding || jewelry || music || patioGarden || sporting) {
        return 0.72
      }
    } else if (weight > 5 && weight <= 10) {
      if (
        automotiveBabyBooksClothing ||
        electroniceIPTVCamerasCarAudio ||
        otherElectronics ||
        printersScanners ||
        toys
      ) {
        return 1.68
      }
      if (cellularPhones) {
        return 9.3
      }
      if (computers) {
        return 19.86
      }
      if (allInOne) {
        return 18.65
      }
      if (chromebooks) {
        return 4.21
      }
      if (laptops) {
        return 11.34
      }
      if (monitors || tvs) {
        return 5.98
      }
      if (headphones) {
        return 16.83
      }
      if (tablets || wearableTech) {
        return 13.47
      }
      if (videoGames) {
        return 4.54
      } else if (videoGamesConsoles) {
        return 4.2
      } else if (home || bedding || jewelry || music || patioGarden || sporting) {
        return 1.26
      }
    } else if (weight > 10 && weight <= 20) {
      if (
        automotiveBabyBooksClothing ||
        electroniceIPTVCamerasCarAudio ||
        otherElectronics ||
        printersScanners ||
        toys
      ) {
        return 2.95
      } else if (cellularPhones) {
        return 16.28
      } else if (computers) {
        return 34.75
      } else if (allInOne) {
        return 22.64
      } else if (chromebooks) {
        return 7.36
      } else if (laptops) {
        return 19.85
      } else if (monitors || tvs) {
        return 10.46
      } else if (headphones) {
        return 29.46
      } else if (tablets || wearableTech) {
        return 23.57
      } else if (videoGames) {
        return 7.94
      } else if (videoGamesConsoles) {
        return 7.35
      } else if (home || bedding || jewelry || music || patioGarden || sporting) {
        return 2.21
      }
    } else if (weight > 20 && weight <= 50) {
      if (
        automotiveBabyBooksClothing ||
        electroniceIPTVCamerasCarAudio ||
        otherElectronics ||
        printersScanners ||
        toys
      ) {
        return 3.68
      } else if (cellularPhones) {
        return 20.35
      } else if (computers) {
        return 43.44
      } else if (allInOne) {
        return 40.81
      } else if (chromebooks) {
        return 9.21
      } else if (laptops) {
        return 24.82
      } else if (monitors) {
        return 13.07
      } else if (tvs) {
        return 11.5
      } else if (headphones) {
        return 36.82
      } else if (tablets || wearableTech) {
        return 29.46
      } else if (videoGames) {
        return 9.93
      } else if (videoGamesConsoles) {
        return 9.19
      } else if (home || bedding || jewelry || music || patioGarden || sporting) {
        return 2.76
      }
    } else if (weight > 50 && weight <= 55) {
      if (
        automotiveBabyBooksClothing ||
        electroniceIPTVCamerasCarAudio ||
        otherElectronics ||
        printersScanners
      ) {
        return 9.21
      } else if (toys) {
        return 4.6
      } else if (cellularPhones) {
        return 50.87
      } else if (computers) {
        return 108.61
      } else if (allInOne) {
        return 102.01
      } else if (chromebooks) {
        return 23.01
      } else if (laptops) {
        return 62.04
      } else if (monitors) {
        return 32.68
      } else if (tvs) {
        return 12.65
      } else if (headphones) {
        return 92.05
      } else if (tablets || wearableTech) {
        return 73.64
      } else if (videoGames) {
        return 24.82
      } else if (videoGamesConsoles) {
        return 22.98
      } else if (home) {
        return 3.45
      } else if (bedding || jewelry || music || patioGarden || sporting) {
        return 6.9
      }
    }
  }

  return 0
}

export const calculateReceiving = ({
  weight,
  weightUnits,
}: Pick<CalculationParams, 'weight' | 'weightUnits'>) => {
  if (
    (weightUnits === 'ounces' && weight > 0 && weight <= 16) ||
    (weightUnits === 'pounds' && weight > 0 && weight <= 2)
  ) {
    return 0.6
  }

  if (weightUnits === 'pounds') {
    if (weight > 2 && weight <= 5) {
      return 0.08
    }
    if (weight > 5 && weight <= 10) {
      return 0.09
    }
    if (weight > 10 && weight <= 20) {
      return 0.15
    }
    if (weight > 20 && weight <= 50) {
      return 0.21
    }
    if (weight > 50 && weight <= 55) {
      return 0.26
    }
  }

  return 0
}

export const calculateCheckIn = ({
  weight,
  weightUnits,
}: Pick<CalculationParams, 'weight' | 'weightUnits'>) => {
  if (
    (weightUnits === 'ounces' && weight > 0 && weight <= 16) ||
    (weightUnits === 'pounds' && weight > 0 && weight <= 2)
  ) {
    return 0.19
  }

  if (weightUnits === 'pounds') {
    if (weight > 2 && weight <= 5) {
      return 0.23
    }
    if (weight > 5 && weight <= 10) {
      return 0.26
    }
    if (weight > 10 && weight <= 20) {
      return 0.46
    }
    if (weight > 20 && weight <= 50) {
      return 0.62
    }
    if (weight > 50 && weight <= 55) {
      return 0.78
    }
  }

  return 0
}
export const calculateSort = ({
  weight,
  weightUnits,
}: Pick<CalculationParams, 'weight' | 'weightUnits'>) => {
  if (
    (weightUnits === 'ounces' && weight > 0 && weight <= 16) ||
    (weightUnits === 'pounds' && weight > 0 && weight <= 2)
  ) {
    return 0.45
  }

  if (weightUnits === 'pounds') {
    if (weight > 2 && weight <= 5) {
      return 0.54
    }
    if (weight > 5 && weight <= 10) {
      return 0.61
    }
    if (weight > 10 && weight <= 20) {
      return 1.08
    }
    if (weight > 20 && weight <= 50) {
      return 1.45
    }
    if (weight > 50 && weight <= 55) {
      return 1.81
    }
  }

  return 0
}

export const calculatePutAway = ({
  weight,
  weightUnits,
}: Pick<CalculationParams, 'weight' | 'weightUnits'>) => {
  if (
    (weightUnits === 'ounces' && weight > 0 && weight <= 16) ||
    (weightUnits === 'pounds' && weight > 0 && weight <= 2)
  ) {
    return 0.22
  }

  if (weightUnits === 'pounds') {
    if (weight > 2 && weight <= 5) {
      return 0.26
    }
    if (weight > 5 && weight <= 10) {
      return 0.3
    }
    if (weight > 10 && weight <= 20) {
      return 0.52
    }
    if (weight > 20 && weight <= 50) {
      return 0.7
    }
    if (weight > 50 && weight <= 55) {
      return 0.88
    }
  }

  return 0
}

export const calculateFulfillment = ({
  weight,
  weightUnits,
}: Pick<CalculationParams, 'weight' | 'weightUnits'>) => {
  if (
    (weightUnits === 'ounces' && weight > 0 && weight <= 16) ||
    (weightUnits === 'pounds' && weight > 0 && weight <= 2)
  ) {
    return {
      b2c: 3.61,
      b2b: 0.36,
    }
  }
  if (weightUnits === 'pounds') {
    if (weight > 2 && weight <= 5) {
      return {
        b2c: 4.31,
        b2b: 0.43,
      }
    }
    if (weight > 5 && weight <= 10) {
      return {
        b2c: 4.9,
        b2b: 0.49,
      }
    }
    if (weight > 10 && weight <= 20) {
      return {
        b2c: 8.62,
        b2b: 0.86,
      }
    }
    if (weight > 20 && weight <= 50) {
      return {
        b2c: 11.56,
        b2b: 1.16,
      }
    }
    if (weight > 50 && weight <= 55) {
      return {
        b2c: 14.48,
        b2b: 1.45,
      }
    }
  }

  return {
    b2c: 0,
    b2b: 0,
  }
}

const IN_TO_FT_CONST = 1728
const JAN_SEP_PERIOD_RATE = 0.75
const OCT_DEC_PERIOD_RATE = 1.88

export const calculateStorage = ({ length, width, height }: CalculateStorageParams) => {
  const volume = length * width * height
  const cubicFeet = volume / IN_TO_FT_CONST

  return {
    [PeriodTypes.jan_sep]: Number(cubicFeet * JAN_SEP_PERIOD_RATE).toFixed(2),
    [PeriodTypes.oct_dec]: Number(cubicFeet * OCT_DEC_PERIOD_RATE).toFixed(2),
  }
}
